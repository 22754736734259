import React from "react"
import { graphql } from "gatsby"
import Layout from '../components/layout';
import SEO from "../components/seo";

class BlogPostTemplate extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title

    const { markdownRemark } = this.props.data // data.markdownRemark holds your post data
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={markdownRemark.frontmatter.title}/>
        <main>
          <article>
            <header>
              <h1 style={{ color: 'var(--textTitle)' }}>
                {markdownRemark.frontmatter.title}
              </h1>
            </header>
            <div
              dangerouslySetInnerHTML={{ __html: markdownRemark.html }}
            />
          </article>
        </main>
      </Layout>
    )
  }
}
export default BlogPostTemplate
export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
      }
    }
  }
`